import * as THREE from '../../../node_modules/three/build/three.module.js';
import { OrbitControls } from '../../../node_modules/three/examples/jsm/controls/OrbitControls.js';

(() => {
	
	const intro = document.querySelector('section#intro');
	
	const size = {
		width: 350,
		height: 600
	};
	
	/** renderer **/
	
	const renderer = new THREE.WebGLRenderer();
	renderer.setSize(size.width, size.height);
	
	/** scene ***/
	
	const scene = new THREE.Scene();
	const texture = new THREE.TextureLoader().load("https://www.transparenttextures.com/patterns/binding-dark.png");
	
	scene.background = texture;
	
	/** camera ***/
	
	const camera = new THREE.PerspectiveCamera(
		45, 
		size.width / size.height,
		0.1,
		100
		);
	
	camera.position.set(0, 0, 20);
	
	/** sphere ***/
	
	const geometry = new THREE.SphereGeometry(3, 256, 256);
	const material = new THREE.MeshStandardMaterial({color: 0xffffff});
	const sphere = new THREE.Mesh(geometry, material);
	
	scene.add(sphere);
	intro.appendChild(renderer.domElement);
	
	/** light ***/
	
	const light = new THREE.PointLight(0xf1f1f1, 1, 100);
	light.position.set(-5, 5, 10);
	scene.add(light);
	
	/** controls **/
	
	const controls = new OrbitControls(camera, renderer.domElement);
	controls.enableZoom = false;
	
	/** animation ***/
	
	function animate() {
		requestAnimationFrame(animate);
	
		light.position.x = Math.sin(Date.now() / 1000) * 10;
		light.position.z = Math.cos(Date.now() / 1000) * 10;
	
		controls.update();
	
		renderer.render(scene, camera);
	};
	
	
	animate();
	
	/** resize ***/
	
	window.addEventListener('resize', () => {
		size.width = window.innerWidth/2;
		size.height = window.innerHeight;
	
		camera.aspect = size.width / size.height;
		renderer.size = size.width / size.height;
	});
	
})();
